import React from 'react';

import {
  ThemeProvider,
	createTheme,
	Typography,
	Button
} from "@material-ui/core";

import { useLocation, useNavigate } from 'react-router-dom'

import { withAuthenticationRequired } from '@auth0/auth0-react';

import Gallery from '../Gallery/Gallery'

import { deleteInvest } from '../../repository/db.js';

const theme = createTheme({
  palette: {
    type: "dark"
  }
});

const newText = (text) => {
	return (
		<Typography gutterBottom component="div">
        {text}
    </Typography>
	)
}



function DetailView(props) {
	const { state } = useLocation()
	const navigation = useNavigate()
	const invest = state.investData

	const onDeleteClick = async (ref) => {
		try {
			await deleteInvest(ref)
			navigation('/')
		} catch (err) {
			console.log(err)
		}
	}

  return (
		<ThemeProvider theme={theme}>
				<div className="container">
					<section>
						<label>Date</label>
						{newText(invest.date)}
					</section>
						
					<section>
						<label>Currency pair</label>
						{newText(invest.currencyPair)}
					</section>

					<section>
						<label>Entry price</label>
						{newText(invest.entryPrice)}
					</section>

					<section>
						<label>Action</label>
						{newText(invest.action)}
					</section>

					<section>
						<label>STOP LOSS</label>
						{newText(invest.stopLoss)}
					</section>

					<section>
						<label>TAKE PROFIT</label>
						{newText(invest.takeProfit)}
					</section>

					<section>
						<label>Patterns Find</label>
						{newText(invest.patternsFind)}
					</section>

					<section>
						<label>Result</label>
						{newText(invest.result)}
					</section>

					<section>
						<label>BEFORE Images</label>
						<Gallery imgs={invest.beforeImages} />
					</section>

					<section>
						<label>REAL PATTERNS</label>
						{newText(invest.realPatterns)}
					</section>

					<section>
						<label>AFTER Images</label>
						<Gallery imgs={invest.afterImages} />
					</section>

					<section>
						<label>TYPE</label>
						{newText(invest.type)}
					</section>
					
					<section>
						<label>You want to delete?</label>
						<Button variant="contained" onClick={() => onDeleteClick(state.ref) }>DELETE</Button>
					</section>
				</div>
		</ThemeProvider>
  );
}

export default withAuthenticationRequired(DetailView, {
});