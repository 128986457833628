import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';

import DateFnsUtils from "@date-io/date-fns";
import {
  TextField,
  Select,
  MenuItem,
  ThemeProvider,
	createTheme,
	Button
} from "@material-ui/core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import dayjs from 'dayjs';

import { saveNewInvest } from '../../repository/db.js';

const theme = createTheme({
  palette: {
    type: "dark"
  }
});

export default function InsertMovement() {
  const { handleSubmit, control, getValues, formState: { errors, touchedFields } } = useForm();
	const [hasError, setHasError] = useState(false)
	const [hasAfterImages, setHasAfterImages] = useState(true)
	const [fullError, setFullError] = useState("")
	const [errorKey, setErrorKey] = useState("")


	if (touchedFields && touchedFields.type){ 
		const typeValue = getValues('type')
		if (hasAfterImages && typeValue !== "BACKTESTING") {
			setHasAfterImages(false)
			if (errorKey === "afterImages") {
				setFullError("I'm kidding, all good!!")
			}
		} else if(!hasAfterImages && typeValue === "BACKTESTING") {
			setHasAfterImages(true)
		}
	}


	const onFormError = (errors) => {
		const key = Object.keys(errors)[0]
		if (fullError.indexOf(key) !== -1) {
			return
		}
		const value = errors[key]
		if (value) {
			const errorType = value.type
			const errorMessage = value.message

			setFullError(`${key} is ${errorType}. ${errorMessage ? errorMessage : ""}` )
			setErrorKey(key)
		} 
	}

	if (errors) {
		onFormError(errors)
	}

  const onSubmit = async data => {
		const date = dayjs(data.date)
		if (!date.isValid()) {
			setHasError(true)
			return
		}
		data.date = date.format('DD/MM/YYYY HH:ss')
		data.beforeImages = data.beforeImages?.split(';')
		data.afterImages = data.afterImages?.split(';')
		try {
			await saveNewInvest(data)
			window.location.reload(false);
		} catch (error) {
			setFullError("Error on save: ", error)
			console.log(error)
		}
	};


  
  return (
		<ThemeProvider theme={theme}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="container">
					<section>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Controller
									name="date"
									control={control}
									render={({ field: { ref, ...rest } }) => (
										<KeyboardDateTimePicker
											margin="normal"
											id="date-picker-dialog"
											label="Date picker dialog"
											format="MM/dd/yyyy hh:mm:ss"
											KeyboardButtonProps={{
												"aria-label": "change date"
											}}
											{...rest}
										/>
									)}
									rules={{ required: true }}
						 />
							</MuiPickersUtilsProvider>
						</section>
						
						<section>
						<label>TYPE</label>
								<Controller
									render={({ field }) => (
										<Select {...field}>
											<MenuItem value={"BACKTESTING"}>BACKTESTING</MenuItem>
											<MenuItem value={"FAKE"}>FAKE</MenuItem>
											<MenuItem value={"REAL"}>REAL</MenuItem>
										</Select>
									)}
									name="type"
									control={control}
									rules={{ required: true }}
								/>
					</section>

						<section>
							<label>Currency pair</label>
							<Controller
									render={({ field }) => <TextField {...field} />}
									name="currencyPair"
									control={control}
									rules={{ required: true }}
							/>
						</section>

						<section>
							<label>Entry price</label>
							<Controller
									render={({ field }) => <TextField type="number" {...field} />}
									name="entryPrice"
									control={control}
									rules={{ required: true }}
							/>
						</section>

						<section>
							<label>Action</label>
								<Controller
									render={({ field }) => (
										<Select {...field}>
											<MenuItem value={"BUY"}>BUY</MenuItem>
											<MenuItem value={"SELL"}>SELL</MenuItem>
										</Select>
									)}
									name="action"
									control={control}
									rules={{ required: true }}
								/>
					</section>

					<section>
							<label>STOP LOSS</label>
							<Controller
									render={({ field }) => <TextField type="number" {...field} />}
									name="stopLoss"
									control={control}
									rules={{ required: true }}
							/>
					</section>

					<section>
						<label>TAKE PROFIT</label>
						<Controller
								render={({ field }) => <TextField type="number" {...field} />}
								name="takeProfit"
								control={control}
								rules={{ required: true }}
						/>
					</section>

					<section>
						<label>Patterns Find</label>
						<Controller
								render={({ field }) => <TextField multiline maxRows={20} {...field} />}
								name="patternsFind"
								control={control}
								rules={{ required: true }}
						/>
					</section>

					<section>
							<label>Result</label>
								<Controller
									render={({ field }) => (
										<Select {...field}>
											<MenuItem value={"WIN"}>WIN</MenuItem>
											<MenuItem value={"LOSE"}>LOSE</MenuItem>
										</Select>
									)}
									name="result"
									control={control}
									rules={{ required: true }}
								/>
					</section>

					<section>
						<label>BEFORE Images (split by ;)</label>
						<Controller
								render={({ field }) => <TextField multiline maxRows={20} fullWidth={true} {...field} />}
								name="beforeImages"
								control={control}
								rules={{ required: true }}
						/>
					</section>

					<section>
						<label>REAL PATTERNS</label>
						<Controller
								render={({ field }) => <TextField multiline maxRows={20}  {...field} />}
								name="realPatterns"
								control={control}
								rules={{ required: true }}
						/>
					</section>

					<section>
						<label>AFTER Images (split by ;)</label>
						<Controller
								render={({ field }) => <TextField multiline maxRows={20} fullWidth={true} {...field} />}
								name="afterImages"
								control={control}
								rules={{ required: hasAfterImages }}
						/>
					</section>

				

					<section>
						{hasError ?
							<label style={{ color: "red", fontWeight: "bold" }}>ERROR {} is required or fixed</label>
							: ""
						}
						<label style={{ color: "red", fontWeight: "bold" }}>Error: {fullError}</label>
						<Button variant="contained" type="submit">Save</Button>
					</section>
				</div>
			</form>
		</ThemeProvider>
  );
}