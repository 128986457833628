import React from 'react';

import './App.css';

import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './components/auth/auth0-provider-with-history.js';

import Header from './components/Header/Header.js';

import { Container, createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "3rem"
    }
  },
  palette: {
    type: "dark",
    
  }
});

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        {/* <div className="wrapper">
          <h1>Application</h1>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />}/>
              <Route path="/preferences" element={<Preferences/>}/>
            </Routes>
          </div> */}
          <ThemeProvider theme={theme}>

            <Container>
              <Header/>
            </Container>
          </ThemeProvider>
        </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;
