'use strict'
import { client, q } from '../config/db.js'

const collectionName = process.env.REACT_APP_DB_NAME

const saveNewInvest = (data) => {
	client.query(
		q.Create(
			q.Collection(collectionName),
			{ data: data },
		)
	)
	// .then((ret) => console.log(ret))
	.catch((err) => console.error('Error: %s', err))
}

const getAllInvest = () => {
	return client.query(
		q.Map(
			q.Paginate(
				q.Documents(
					q.Collection(collectionName)
				)
			),
			(investRef) => q.Get(investRef)
		)
	)
	.then((ret) => ret)
	.catch((err) => console.error(err))
}

const deleteInvest = (ref) => {
	return client.query(
		q.Delete(q.Ref(q.Collection(collectionName), ref))
		)
	// .then((ret) => console.log(ret))
	.catch((err) => console.error('Error: %s', err))
}

export { saveNewInvest, getAllInvest, deleteInvest }