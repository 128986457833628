import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import InsertMovement from '../Forms/insert-movement';

const InsertInvest = () => {
	const { user } = useAuth0();

  return(
   <>
    <InsertMovement/>
   </>
  );
}

export default withAuthenticationRequired(InsertInvest, {
});