import React from "react";
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

const itemGallery = (url) => {
    return (
        <Item
      original={url}
      thumbnail={url}
      width="1024"
      height="768"
      key={new Date().getTime()}
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={url}  style={{ height: 150, width: 150 }}/>
      )}
      </Item>
    )
}

const MyGallery = ({imgs}) => {
  return (
    <Gallery>
        {imgs && imgs.map(img => {
            return itemGallery(img)
        })}
    </Gallery>
    )
}

export default MyGallery